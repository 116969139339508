import { ActionFunctionArgs } from "@remix-run/node";
import { Form, json, Link, MetaFunction, useActionData, useFetcher, useSubmit } from "@remix-run/react";
import { Mail } from "lucide-react";
import { useState } from "react";
import invariant from "tiny-invariant";
import { supabase } from "~/.server/supabase";
import Discord from "~/components/Discord";
import { Button } from "~/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "~/components/ui/dialog";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { Separator } from "~/components/ui/separator";
import { updateSubscriptionDataWithStripeId } from "~/models/customer";

export const meta: MetaFunction = () => {
    return [
        { title: "Find Power People - Connect with the Most Relevant People at Professional Events" },
        { name: "description", content: "Discover and connect with relevant people at professional events effortlessly with our SaaS tool. Enhance your networking and sales experiences and make meaningful connections with ease. Try it now!" },
        { name: "keywords", content: "Professional events, SDR tool, event networking, find relevant people, event connections, professional networking software, sales research tool" },
        { property: "og:title", content: "Find Power People - Connect with the Most Relevant People at Professional Events" },
        { property: "og:description", content: "Discover and connect with relevant people at professional events effortlessly with our SaaS tool. Enhance your networking and sales experiences and make meaningful connections with ease. Try it now!" },
        { property: "og:image", content: "https://953697314.imgix.net/landing/people.png?auto=format,enhance" },
        { property: "og:url", content: "https://findpowerpeople.com" },
        { property: "og:type", content: "website" },
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: "Find Power People - Connect with the Most Relevant People at Professional Events" },
        { name: "twitter:description", content: "Discover and connect with relevant people at professional events effortlessly with our SaaS tool. Enhance your networking and sales experiences and make meaningful connections with ease. Try it now!" },
        { name: "twitter:image", content: "https://953697314.imgix.net/landing/people.png?auto=format,enhance" },
    ];
};

interface ActionData {
    message?: string;
    error?: string;
}

async function sendDiscordMessage(email: string) {
    try {
        await fetch('https://discord.com/api/webhooks/1271653823588597820/ReQBkvN4vWASk6VLiHh75zdVVtTOLlr3M1UVNmWBNW2Y6vzK0Sd_djnCvVsCSbTPRC9E', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                content: `${email} signed up for waitlist`
            }),
        })
    } catch (error) {
        console.error('Error sending discord message:', error)
    }
}

export async function action({ request }: ActionFunctionArgs) {
    const formData = await request.formData();
    invariant(formData, 'No form data provided');
    const email: string = formData.get('email')
    invariant(email, 'No email provided');
    // TODO - save email to database
    const { error } = await supabase.from('hh_waitlist').insert({ email: email.toLowerCase() })
    if (error) {
        console.error('Error inserting waitlist:', error)
        if (error.message.includes('duplicate key value violates unique constraint')) {
            return json({ message: 'You are already on the waitlist.' });
        }
        return json({ error: 'Error joining - please try again' }, { status: 500 });
    }
    sendDiscordMessage(email) // call async so as not to slow down response
    return json({ message: 'Thank you for joining the waitlist. We will be in touch.' });
}

export default function About() {
    const fetcher = useFetcher();
    const submit = useSubmit();
    const actionData = useActionData<ActionData>();
    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);
    return (
        <div className="flex flex-col items-center mt-5 ms-4">
            <h1 className="text-3xl underline text-center mb-4">Never waste time at professional events again.</h1>
            <p className="text-center mb-6">
                Wish you could just show up at an event and instantly identify the key people who could transform your business or career? With Find Power People, you can.
            </p>

            <h3 className="text-left mb-2">How It Works:</h3>
            <ol className="list-decimal list-inside mb-6">
                <li><strong>Upload Your Event File:</strong> Provide us with a file from the event page.</li>
                <li><strong>We Analyze and Enrich:</strong> We connect names with professional identities.</li>
                <li><strong>Get Your Results:</strong> Receive an enriched, searchable, and filterable CSV or Google Sheet.</li>
            </ol>

            <h3 className="text-left mb-2">Benefits:</h3>
            <ul className="list-disc list-inside mb-6">
                <li>Easily find your next prospect, great new hire, or new manager.</li>
                <li>Save time and seize opportunities efficiently.</li>
            </ul>

            <h3 className="text-left mb-2">Pricing:</h3>
            <p className="text-left !m-0">$20 per month for 10 credits (1 credit per 50 people at an event).</p>

            <p className="font-bold text-center mb-6">Don't waste time. Don't miss opportunities. Find Power People.</p>

            <div>
                {actionData?.message && <p className={"mb-2 italic"}>{actionData.message}</p>}
            </div>
            <div>
                {actionData?.error && <p className={"mb-2 italic"}>{actionData.error}</p>}
            </div>
            <div className="flex flex-col">
                {!actionData?.message &&
                    <div className="flex flex-row justify-center mb-6 h-10">
                        <div className="flex items-center space-x-4">
                            {/*
                            <Dialog open={open} onOpenChange={setOpen}>
                                <DialogTrigger asChild>
                                    <Button>Join the Waitlist</Button>
                                </DialogTrigger>

                                <DialogContent className="sm:max-w-[425px]">
                                    <DialogHeader>
                                        <DialogTitle>Join Waitlist</DialogTitle>
                                        <DialogDescription>
                                            Enter your email to join the waitlist.
                                        </DialogDescription>
                                    </DialogHeader>
                                    <div className="grid gap-4 py-4">
                                        <div className="grid grid-cols-4 items-center gap-4">
                                            <Label htmlFor="name" className="text-right">
                                                Email
                                            </Label>
                                            <Input
                                                id="email"
                                                value={email}
                                                className="col-span-3"
                                                onChange={(event) => setEmail(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <DialogFooter>
                                        <Button type="submit" onClick={(e) => {
                                            const formData = new FormData();
                                            formData.set('email', email);
                                            submit(formData, { method: 'post' });
                                            setOpen(false);
                                        }}>Submit</Button>
                                    </DialogFooter>
                                </DialogContent>
                            </Dialog>

                            <Separator orientation="vertical" /> */}
                            <a href="/login">
                                <Button>Signup | Login</Button>
                            </a>
                        </div>
                    </div>}
                <div>
                    <Separator />
                    <div className="flex flex-col ml-2 mb-2">
                        <div className="flex flex-col items-center"> {/* Centering the child div */}
                            <p>Support</p>
                            <div className="flex flex-row">
                                <a href="mailto:info@findpowerpeople.com" className="mr-2">
                                    <Mail />
                                </a>
                                <a href="https://discord.gg/U2Ume3gFnR">
                                    <Discord />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <img src="https://953697314.imgix.net/landing/people.png?auto=format,enhance" alt="People" className="mt-5 w-1/2" />
        </div>
    );
}
